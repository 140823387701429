body {
    margin: 0;
    font-family: "Playfair Display", serif;
    font-family: "Lato", sans-serif;
}

code {
    font-family: "Playfair Display", serif;
    font-family: "Lato", sans-serif;
}

@font-face {
    font-family: Abuget;
    src: url(../src/fonts/Abuget/Abuget.ttf);
}

:root {
    --font-playfair: "Playfair Display", serif;
    --font-lato: "Lato", sans-serif;
}